$logoWrapperSize: 110px;

@mixin CheckoutHeader() {
  display: flex;
  width: 100%;
  height: 150px;
  position: relative;
  justify-content: center;
  align-items: center;

  .fillBackgroundImg {
    height: 100%;
    width: 100%;
    position: absolute;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: black;
      opacity: 0.3;
    }
  }

  .logoWrapper {
    position: relative;
    display: flex;
    height: $logoWrapperSize;
    width: $logoWrapperSize;
    background-color: white;
    border-radius: 10000%;
    justify-content: center;
    align-items: center;
    box-shadow: $lighBoxShadow;
    img {
      height: 50%;
      max-height: 100px;
    }
  }
}

.Header {
  @include CheckoutHeader;
}

  