
@mixin CheckoutInfo() {
  display: flex;
  padding: $broadSpace;
  box-shadow: $lighBoxShadow;
  flex-direction: column;

  .infoSection {
    margin-top: $broadSpace;

    h2 {
      font-family: $titleFontFamily;
      margin-bottom: $closeSpace;
    }

    p {
      font-size: $mediumTextSize;
    }

    .inputWrapper {
      margin-top: $closeSpace;
      
      label {
        font-size: $mediumTextSize;
      }

      input {
        @include formInput();
        margin-top: calc(#{$closeSpace} / 2);
      }
    }
  }

  .infoSection:first-child {
    margin-top: 0;
  }

  .orderTypeSelector {
    margin-top: $closeSpace;

    button {
      @include button();
      background: white;
      border: 0.3px solid black;
      border-radius: 5px;
      padding: 8px;
      margin-right: $closeSpace;

      &.selected {
        background: black;
        color: white;
      }
    }
  }
}

.Info {
  @include CheckoutInfo();
}
