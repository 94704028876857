
@import '../variables';

@mixin categoriesList() {
  display: flex;
  flex-wrap: wrap;

  .categoryItem {
    padding: calc(#{$closeSpace} / 2) $closeSpace;
    border-radius: 100px;
    box-shadow: 2px 2px 15px 2px rgba(131, 131, 131, 0.185);
    margin-right: $closeSpace;
    margin-bottom: $closeSpace;
    font-size: $mediumTextSize;
    font-weight: lighter;

    &.pressed {
      background: black;
      color: white;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
