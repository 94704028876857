/** */
// @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;600;700&family=Mulish:wght@300;400;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;600;700;800&family=Mulish:wght@300;400;700&display=swap');

$broadSpace: 5.8vw;
$broadSpace: 32px;
$smallDevBroadSpace: 50px;
$closeSpace: 16px;
$lightGray: rgb(187, 187, 187);
$thinGray: rgb(218, 218, 218);
$brandColor: #1FBDB7;
$brandColorLight: #BCEEED;
$brandColorDark: #0E9D98;
$accentColor: #FBA017;
$errorColor: #ea4d33;
$darkGrayColor: #999;
$headerHeight: 90px;
$mobileHeaderHeight: 60px;
$mainSectionHeight: 600px;
$smallScreen: 480px;
$mediumScreen: 768px;
$bigScreen: 1024px;
$titleSize: 50px;
$titleFontFamily: 'Josefin Sans', sans-serif;
$textFontFamily: 'Mulish', sans-serif;

//Text sizes
$tinyTextSize: 8px;
$smallTextSize: 10px;
$mediumTextSize: 14px;
$bigTextSize: 18px;
$largeTextSize: 24px;
$xLargeTextSize: 32px;
//Box shadows
$lighBoxShadow: 2px 2px 8px 2px rgba(37, 37, 37, 0.185);
$topOnlyLightBoxShadow: 0 -7px 5px -7px #bbbb;
//Borders
$lightBorder: 1px solid rgba(173, 173, 173, 0.288);
//Border radius
$brandBorderRadius: 5px;

@mixin button() {
  border: none;
  text-decoration: none;
  padding: calc( #{$closeSpace} / 4) calc(#{$closeSpace} / 2);
}

@mixin brandButton {
  @include button();
  background-color: $brandColor;
  color: white;
  font-weight: 600;
  text-align: center;
}

@mixin landingBrandButton {
  @include brandButton();
  padding: 16px 40px;
  font-size: 25px;
}

@mixin mainPageTitle {
  font-family: $titleFontFamily;
  font-size: $titleSize;

  @media screen and (max-width: $mediumScreen) {
    font-size: 2.5rem;
  }
  margin-bottom: $closeSpace;
}

@mixin simpleText {
  font-size: 18px;
  margin-bottom: $closeSpace;
  line-height: 166%;
  font-weight: 300;
}

@mixin formInput {
  margin-top: $closeSpace;
  width: 100%;
  padding: 12px;
  border: 1px solid rgba($brandColorDark, 0.479);
  border-radius: 4px;
  font-size: 16px;

  &.invalid {
    border-color: $errorColor;
  }
}

@mixin formSubmit {
  width: 100%;
  margin-top: calc(#{$closeSpace} * 2);
  font-size: 16px;
  padding: 12px;
}

@mixin commentInput {
  border: 1px solid rgba(131, 131, 131, 0.336);
  padding: $closeSpace;
}

@mixin simpleParragraph {
  font-size: $mediumTextSize;
}

@mixin inlineULList {
  display: flex;

  li {
    list-style-type: none;
    margin-right: $closeSpace;

    &:last-child {
      margin-right: 0;
    }
  }
}