
@import '../variables';

@mixin categoriesProductSearch {
  margin-top: $closeSpace;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

}
