@mixin CartIcon {
  position: relative;
  z-index: 1;
  .itemsCount {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: -5px;
    font-size: $smallTextSize;
    font-weight: bolder;
    background-color: $brandColor;
    border-radius: 100%;
    color: white;
    width: 18px;
    height: 18px;
    box-shadow: $lighBoxShadow;
  }

  &:hover {
    cursor: pointer;
  }
}

.CartIcon { 
  @include CartIcon();
}
