@import '../../../assets/styles/variables';

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 100%;

  .logo {
    height: 40px;
    max-height: 50%;
  }

  .mainNav {

    ul {
      @include inlineULList;
      li {
        font-size: 18px;
      }
    }
  }

  .mainCta {
    @include brandButton();
    padding: $closeSpace / 1.3 $closeSpace;
    border-radius: 4px;
    font-size: 17px;
    background-color: black;
  }

  @media screen and (max-width: $mediumScreen) {
    .logo {
      height: 8vw;
      max-height: 40%;
    }

    .mainNav {
      display: none;
    }

    .mainCta {
      font-size: 14px;
    }
  }
}