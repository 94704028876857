
@import './variables';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: $textFontFamily;
  outline: none;
}

html, body {
  height: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $titleFontFamily;
}

button {
  
  &:hover {
    cursor: pointer;
  }
}

a {
  color: inherit;
}

// .App {
//   max-width: 700px;
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// }
