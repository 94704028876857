@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.Footer {
  width: 100%;
  display: flex;
  padding: $broadSpace * 1.4 $broadSpace;
  background-color: black;
  color: white;

  .wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .footerItem {
      width: calc(100% / 3 - #{$broadSpace});
      display: flex;
      flex-direction: column;

      h3 {
        margin-bottom: $closeSpace / 2;
      }

      & .storeInfo {
        display: flex;
        flex-direction: column;

        div {
          margin-bottom: $broadSpace;

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        .socialIcons {
          color: white;
          display: flex;
          padding: 0;
          margin-top: $broadSpace;

          .socialItem {
            list-style-type: none;
            margin-right: $closeSpace / 3;

            svg {
              fill: white;
            }
          }
        }
      }

      & .schedule {
        .days {

          li {
            list-style-type: none;
            display: flex;
            justify-content: space-between;
            margin-bottom: $closeSpace / 2;

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }

      & .powered {
        display: flex;
        justify-content: center;
        margin-top: auto;
        small {
          color: white;
          text-align: center;
          align-self: flex-end;
        }
      }
    }
  }

  @media screen and (max-width: $mediumScreen) {
    .wrapper {
      flex-direction: column;

      .footerItem {
        width: 100%;
        margin-bottom: $broadSpace;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}
