@import '../../../assets/styles/variables';

.ProductListItem {
  display: flex;
  margin-bottom: $closeSpace;
  font-size: $mediumTextSize;
  padding-bottom: $closeSpace;

  .imgWrapper {
    width: 60px;
    height: 60px;
    margin-right: $closeSpace;

    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .defaultImg {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      background: black;
      color: white;
      font-size: $largeTextSize;
      font-weight: bold;
    }
  }

  .productInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .name {
      font-size: 15px;
      // margin-bottom: calc(#{$closeSpace} / 3);
    }

    .quantity {
      color: rgb(128, 128, 128);
      font-size: 12px;
    }  
  }

  .pricing {
    margin-left: auto;

    .price {
      font-size: $bigTextSize;
    }
  }  
}
