@mixin CheckoutCart() {
  display: flex;
  box-shadow: $lighBoxShadow;
  padding: $broadSpace;
  flex-direction: column;

  .cartTitle {
    margin-bottom: $closeSpace;
  }
  .cartItemList {
    overflow-y: auto;
    margin-right: -10px;
    padding-right: 10px;

    .CheckoutCartItem {
      display: flex;
      border-bottom: $lightBorder;
      margin-bottom: $closeSpace;
      font-size: $mediumTextSize;
      padding-bottom: $closeSpace;
  
      .imgWrapper {
        width: 60px;
        height: 60px;
        margin-right: $closeSpace;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
  
        .defaultImg {
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;
          background: black;
          color: white;
          font-size: $largeTextSize;
          font-weight: bold;
        }
      }
  
      .productInfo {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .name {
          font-size: 15px;
          // margin-bottom: calc(#{$closeSpace} / 3);
        }
  
        .quantity {
          color: rgb(128, 128, 128);
          font-size: 12px;
        }  
      }
  
      .price {
        margin-left: auto;
        font-size: $bigTextSize;
      }
    }
  }

  .cartFooter {
    display: flex;
    margin-top: auto;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    .pricing {
      margin-bottom: $closeSpace;
      margin-top: $closeSpace;
      text-align: right;

      .deliveryFee {
        margin-bottom: $closeSpace / 2;
      }
      .total {
        font-size: $largeTextSize;
      }
    }

    .btns {
      display: flex;
      justify-content: flex-end;

      button {
        @include button();
        padding: calc(#{$closeSpace} / 2);
        margin-left: calc(#{$closeSpace} / 2);

        &.orderButton {
          background-color: $brandColor;
          color: white;
          border: 0.3px solid $brandColor;
          width: 150px;
          max-width: 100%;
        }

        &.backButton {
          box-sizing: content-box;
          background-color: white;
          color: rgb(131, 131, 131);
          border: 0.3px solid rgb(131, 131, 131);
        }
      }
    }
  }
}

.CheckoutCart {
  @include CheckoutCart();
}
