
@import './variables';
@import './restaurant_page/categoryList.scss';
@import './restaurant_page/productList.scss';
@import './restaurant_page/categoryProductSearch.scss';
@import './restaurant_page/productFilter.scss';
@import './restaurant_page/addProductModal.scss'; 
@import './restaurant_page/cartSidebar.scss';
@import './restaurant_page/cartIcon.scss';

.restaurantPage {
  display: flex;
  flex-direction: column;
  padding: $closeSpace $broadSpace;

  .mainTitle {
    @include mainPageTitle();
    margin-top: $broadSpace;
  }

  .categoriesProductSearch {
    @include categoriesProductSearch();

    .categoriesList {
      @include categoriesList();
    }
  }

  .productFilter {
    @include ProductFilter();
  }

  .ProductList {
    margin-top: calc(#{$broadSpace} * 2);
    @include ProductList();
  }

  @media screen and (max-width: $mediumScreen) {
    margin-top: $mobileHeaderHeight;
    padding: $closeSpace $closeSpace;

  }
}
