@import '../../assets/styles/variables';
@import '../../assets/styles/web/base';

.Landing {
  .content {
    margin-top: $broadSpace;
    margin-bottom: $broadSpace;
  }

  .contentWrapper {
    @include landingPageContentWrapper;
    margin-top: 0;
  }

  .aboveTheFold {
    background: linear-gradient(rgba(52, 191, 226, 0.212) 75%, transparent 100%);
    height: 800px;

    .content {
      display: flex;
      margin-top: $broadSpace * 3;
      justify-content: space-between;

      .leftSection {
        width: 46%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .mainTitle {
          margin-top: $broadSpace;
          font-size: 60px;
          margin-bottom: $broadSpace;
        }

        .mainDescription {
          @include webText;
          margin-bottom: $broadSpace;
        }

        .ctaSection {
          margin-bottom: $broadSpace * 2;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .cta {
            @include brandButton;
            font-size: 18px;
            padding: $closeSpace $closeSpace * 1.5;
            border-radius: $brandBorderRadius;
          }

          small {
            align-self: flex-end;
            color: $lightGray;
            font-weight: 100;
          }
        }
          
        .clientsLogos {
          ul {
            @include inlineULList;
            li {
              img {
                height: 50px;
              }
            }
          }
        }
      }
  
      .rightSection {
        width: 54%;

        .imgWrapper {
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 90%;
          }
        }
      }
    }
  }

  .contentBridge {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 800px;
    padding: $broadSpace * 2 0;
    margin-bottom: $broadSpace * 3;

    h2 {
      font-size: 35px;
      text-align: center;
      font-weight: normal;
      margin-bottom: $broadSpace;
    }

    p {
      @include webText;
      text-align: center;
      max-width: 80%;
    }
  }

  section {
    margin-top: $broadSpace;
    margin-bottom: $broadSpace;

    .content {
      display: flex;
    }

    .imgWrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 90%;
      }
      .onlineOrdersImg {
        box-shadow: 1px 2px 10px 5px rgba(10, 10, 10, 0.301);
      }
    }

    &.feature {
      margin-top: $broadSpace * 2;
      padding: $closeSpace 0;

      .featureTitle {
        font-size: 40px;
        margin-bottom: $broadSpace;
      }

      .featureDescription {
        @include webText;
      }

      &:nth-child(odd) {
        .content {
          .leftSection {
            width: 45%;
          }

          .rightSection {
            width: 55%;
          }
        } 
      }

      &:nth-child(even) {
        .content {
          .leftSection {
            width: 55%;
          }

          .rightSection {
            width: 45%;
          }
        } 
      }
    }

    &.carrouseWrapper {
      margin-top: $broadSpace * 3;
      .contentWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        
        .carrousel {
          height: 500px;

          img {
            box-shadow: 1px 2px 50px 10px rgba(10, 10, 10, 0.301);
          }
        }
      }

    }
  }

  @media screen and (max-width: $mediumScreen) {
    .aboveTheFold {
      height: auto;
      .content {
        flex-direction: column;
        margin-top: $broadSpace;

        .leftSection {
          width: 100%;
          align-items: center;
          text-align: center;

          .mainTitle {
            font-size: 40px;
          }

          .mainDescription {
            font-size: 14px;
            line-height: 20px;
          }

          .ctaSection {
            margin-bottom: $broadSpace;

            .cta {
              font-size: 14px;
            }

            small {
              font-size: 9px;
            }
          }

          .clientsLogos {
            ul {
              li {
                img {
                  height: 40px;
                }
              }
            }
          }
        }

        .rightSection {
          width: 100%;
          margin-top: $broadSpace * 2;
        }
      }
    }

    .contentBridge {
      margin-bottom: $broadSpace;
      padding: $closeSpace $broadSpace;
      h2 {
        font-size: 30px;
      }
    }

    section {
      &.feature {
        margin-top: $broadSpace;
        .featureTitle {
          font-size: 30px;
        }

        &:nth-child(odd), &:nth-child(even) {
          .content {
            flex-direction: column;
            .leftSection {
              width: 100%;
            }
            
            .rightSection {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
