
@mixin CartSidebar {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  max-width: 450px;
  justify-self: flex-end;
  background-color: white;
  display: flex;
  flex-direction: column;

  .cartHeader {
    padding: $broadSpace;
    border-bottom: $lightBorder;
  }

  .cartItemList {
    margin-top: $closeSpace;
    overflow-y: auto;

    .CartItem {
      padding: calc(#{$closeSpace} * 1.2) $broadSpace;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      border-bottom: $lightBorder;

      .itemInfo {
        width: 65%;
        display: flex;
        flex-direction: column;

        .name {
          // margin-bottom: calc(#{$closeSpace} * 0.6);
        }

        .comment {
          font-size: $mediumTextSize;
          color: $lightGray;
        }
      }

      .quantitySelector {
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;

        button {
          @include button();
          width: 30px;
          color: $brandColor;
          background-color: white;
          font-size: 18px;
          font-weight: 800;
        }

        .quantity {
        }
        
      }
      
      .price {
        width: 15%;
        text-align: right;
        font-size: $mediumTextSize;
        font-weight: 800;
        color: gray;
      }
    }
  }

  .cartFooter {
    display: flex;
    align-self: flex-end;
    justify-content: space-between;
    margin-top: auto;
    padding: $broadSpace;
    flex-wrap: wrap;
    width: 100%;

    button {
      @include button();
      padding: $closeSpace $broadSpace;
      
    }

    .clearCartButton {
      background-color: transparent;
    }

    .toCheckoutButton {
      @include brandButton;
      padding: $closeSpace ;
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 130px;
    }
  }
}

.CartSidebar {
  @include CartSidebar();
}
