
@import '../variables';

$lightGray: rgba(78, 78, 78, 0.973);
$maxLandingPageWidth: 1150px;

@mixin webText {
  font-size: 18px;
  color: $lightGray;
  font-weight: lighter;
  line-height: 28px;
  margin-bottom: $closeSpace;
  
  @media screen and (max-width: $mediumScreen) {
    font-size: 14px;
    line-height: 20px;
  }
}

@mixin landingPageContentWrapper {
  width: $maxLandingPageWidth;
  max-width: 100%;
  margin: $closeSpace auto;
  padding: 0 $broadSpace;
}

html, body {
  height: auto;
}
