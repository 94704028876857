
@mixin RightTopCloseButton() {
  border: none;
  background: none;
  position: absolute;
  top: #{$closeSpace};
  right: #{$closeSpace};
  background-color: white;
  box-shadow: 1px 1px 10px 1px rgba(172, 172, 172, 0.192);
  padding: calc(#{$closeSpace} * 0.6);
  display: flex;
  align-items: center;
  justify-content:center;
  border-radius: 100%;
}

.RightTopCloseButton {
  @include RightTopCloseButton();
}