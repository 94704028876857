
@import './variables';
@import './checkout/header.scss';
@import './checkout/info.scss';
@import './checkout/checkoutCart.scss';

.Checkout {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: $broadSpace;

  .contentWrapper {
    width: 900px;
    max-width: 95%;
  }

  .infoCartWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: $closeSpace;

    .Info {
      width: calc(60% - #{$closeSpace / 2});
    }

    .CheckoutCart {
      width: calc(40% - #{$closeSpace / 2});
    }

    @media screen and (max-width: $mediumScreen) {
      flex-wrap: wrap;

      .Info {
        width: 100%;
      }

      .CheckoutCart {
        width: 100%;
      }
    }
  }
}