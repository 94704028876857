
@import '../util/backdrop.scss';
@import '../util/modal.scss';
@import '../util/rightTopCloseButton.scss';

@mixin AddProductModal() {
  @include largeModal();
  position: relative;
  background-color: white;
  display: flex;
  justify-content: space-between;

  .leftSection {
    width: 50%;
    display: flex;
    justify-content: center;
    align-self: center;

    img {
      max-width: 100%;
      max-height: 25vh;
    }
  }

  .rightSection {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;

    .productInfo {
      display: flex;
      flex-direction: column;
      padding: calc(#{$broadSpace} * 2) $broadSpace 0 $broadSpace;

      .productName {
        @include mainPageTitle();
        font-size: $xLargeTextSize;
        margin-bottom: $broadSpace;
      }

      .productDescription {
        margin-bottom: $broadSpace;
        font-size: 14px;
      }
  
      .sectionTitle {
        font-size: $largeTextSize;
        margin-bottom: $closeSpace;
      }
  
      .commentInput {
        @include commentInput();
      }
    }
    
    .addToCartSection {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin: auto 0 0 0;
      padding: $closeSpace $broadSpace;
      box-shadow: $topOnlyLightBoxShadow;
      
      .quantitySelector {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 40%;
        border-radius: 4px;
        border: 1px solid rgba(194, 194, 194, 0.397);
        padding: calc(#{$closeSpace} / 5);

        button {
          @include button();
          width: 30px;
          color: $brandColor;
          background-color: white;
          font-size: 18px;
          font-weight: 800;
        }
      }

      .addToCartButton {
        @include brandButton();
        display: flex;
        justify-content: center;
        align-items: center;
        width: 125px;
        max-width: 100%;
      } 
    }
  }

  @media screen and (min-width: $mediumScreen) and (max-width: $bigScreen) {

  }

  @media screen and (max-width: $mediumScreen) {
    flex-direction: column;
    
    .leftSection {
      width: 100%;
      height: 200px;
      max-height: 100%;

      img {
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
      }
    }

    .rightSection {
      width: 100%;

      .productInfo {
        padding-top: $broadSpace;

        .productName {
          font-size: 2rem;
        }

        .sectionTitle {
          font-size: 20px;
        }
      }
    }
  }
}

.AddProductModal {
  @include AddProductModal();
}
