
@mixin ProductFilter() {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  input[type="text"] {
    width: 100%;
    border: none;
    font-size: $xLargeTextSize;
    border-bottom: 2px solid $brandColor;
    line-height: calc(#{$largeTextSize * 2});
    background-image: url('../../images/searchIcon.svg');
    background-repeat: no-repeat;
    background-position-y: 50%;
    padding: 4px 0 4px calc(#{$broadSpace} * 1.5);

    &::placeholder {
      color: $thinGray;
      font-weight: 800;
    }
  }

  .closeIcon {
    position: absolute;
    right: 0;
  }
}