
@mixin ProductList {
  display:  flex;
  flex-wrap: wrap;
  margin-right: -$closeSpace;
  margin-left: -$closeSpace;

  .ProductItem {
    display: flex;
    flex-direction: column;
    width: calc((((100% - (#{$closeSpace} * 2) * 3))) / 3);
    margin: 0 $closeSpace;
    box-shadow: $lighBoxShadow;
    margin-bottom: calc(#{$broadSpace} * 1.8);
    transition: 0.4s ease-out;
    border-radius: 7px;

    .productPictureWrapper {
      height: 180px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }
    }

    .productInfo {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      padding: $closeSpace;
      width: 100%;
      height: 100%;

      .info {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 80%;
        justify-content: space-between;
        margin-right: calc(#{$closeSpace} / 2);

        .name {
          font-size: $largeTextSize;
        }

        .productPresName {
          margin-bottom: $closeSpace;

          .nameProd {
            display: flex;
            margin-bottom: calc(#{$closeSpace} / 3);
            
          }
  
          .namePres {
            color: gray;
            font-weight: lighter;
          }
        }

        .price {
          margin-top: calc(#{$closeSpace} / 3);
          font-family: $titleFontFamily;
          font-size: $largeTextSize;
          font-weight: lighter;
        }
      }

      button.add {
        @include brandButton();
        min-width: 100px;
        width: 20%;
        padding: calc(#{$closeSpace} / 2);
      }
    }

    @media screen and (min-width: $mediumScreen) and (max-width: $bigScreen) {
      width: calc((100% - (#{$closeSpace} * 2) * 2) / 2);
    }

    @media screen and (max-width: $mediumScreen) {
      width: 100%;
      margin-bottom: $broadSpace;

      .productInfo {
        flex-direction: column;
        .info {
          width: 100%;
          flex-direction: row;
          justify-content: space-between;

          .productPresName {
            .nameProd {
              line-height: 16px;
            }
          }
          .price {
            margin-top: 0;
          }
        }
        
        button.add {
          width: 100%;
        }
      }
    }

    &:hover {
      // transform: scale(1.05);
      box-shadow: 1px 2px 10px 2px #2866636b;
    }
  }
}