@import '../variables';

$mobileHeaderHeight: 60px;

.HomeHeader {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: calc(#{$closeSpace} * 1.2) $broadSpace;
  margin: 0 $broadSpace;
  border-bottom: 1px solid rgba(15, 15, 15, 0.281);

  .logoImg {
    height: 60px;
  }

  .merchantName {
    position: absolute;
    text-align: center;
    font-size: 16px;
    left: 50%;
    transform: translateX(-50%);
  }

  .cart {
    height: 30px;
  }

  @media screen and (min-width: $mediumScreen) and (max-width: $bigScreen) {
    
  }

  @media screen and (max-width: $mediumScreen) {
    position: fixed;
    width: 100%;
    height: $mobileHeaderHeight;
    top: 0;
    padding: calc(#{$closeSpace / 1.1}) calc(#{$closeSpace} * 2);
    border: none;
    background: white;
    margin: 0;
    box-shadow: $lighBoxShadow;
    .logoImg {
      height: 40px;
    }
  }
}