@import '../../../assets/styles/variables';
@import '../../../assets/styles/web/base';

.Carrousel {
  display: flex;
  height: 100%;

  .show {
    height: 100%;
    max-width: 100%;
    * {
      max-height: 100%;
      max-width: 100%;
    }
  }
}
