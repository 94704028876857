@import '../../assets/styles/variables';

.PostOrder {
  padding: $broadSpace * 2;

  .contentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: $mediumScreen;
    max-width: 100%;
    margin: 0 auto;

    .merchantLogo {
      margin-bottom: $broadSpace;
      max-height: 80px;
      max-height: 150px;
    }

    .thankYouText {
      margin-bottom: $broadSpace;
      text-align: center;
    }

    .orderNumber {
      color: $darkGrayColor;
      margin-bottom: $broadSpace;
      font-weight: normal;
    }

    .mainTextBox {
      margin-bottom: $broadSpace;
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        @include simpleParragraph();        
        text-align: center;
        color: #676767;
        margin-bottom: $closeSpace / 3;

        &:last-of-type {
          display: block;
          margin-bottom: 20px;
        }
      }

      .backToMerchantBtn {
        @include brandButton();
        padding: $closeSpace / 2 $closeSpace;
      }
    }

    .orderInfo {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: $closeSpace * 1.6;
      box-shadow: $lighBoxShadow;
      width: 100%;

      .infoWrapper {
        width: 100%;
        border: 1px solid $thinGray;
        padding: $closeSpace * 1.5 $closeSpace * 1.2;
        margin-bottom: $closeSpace;

        &:last-of-type {
          margin-bottom: 0;
        }

        .title {
          display: block;
          font-weight: bold;
          font-family: $titleFontFamily;
          margin-bottom: $broadSpace;
        }
      }

      .orderCost {
        display: flex;
        flex-direction: column;

        .costList {
          display: flex;
          flex-direction: column;
          padding-bottom: $closeSpace;
          margin-bottom: $closeSpace;
          border-bottom: 1px solid $thinGray;

          .costItem {
            display: flex;
            justify-content: space-between;
            margin-bottom: $closeSpace / 2;
          }          
        }

        .totalsInfo {
          font-weight: bold;
          display: flex;
          justify-content: space-between;
        }
      }

      @media screen and (max-width: $mediumScreen) {
        padding: $closeSpace * 1.2;
      }
    }
  }

  @media screen and (max-width: $mediumScreen) {
    padding: $broadSpace $closeSpace;
  }
}